<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="8">
      <v-card>
        <v-card-title>
          {{
            $t("crudActions." + (id
              ? "updateModel"
              : "createModel"), { model: $t("models.users.single") })
          }}
        </v-card-title>
        <v-card-text>
          <validation-observer ref="validationObserver">
            <user-form
              :user.sync="user"
              :validations="validationRules"
              :zones="zones" />
          </validation-observer>
          <v-row
            align="center"
            justify="center">
            {{ $t("attributes.users.privileges") }}
          </v-row>
          <v-card
            v-for="(privileges, category) in assignablePrivileges"
            :key="category"
            field-name="privileges"
            elevation="2"
            class="px-3 mt-3">
            <v-card-title>
              {{ category }}
            </v-card-title>
            <v-chip-group
              v-model="user.privileges"
              active-class="primary--text"
              multiple>
              <v-chip
                v-for="privilege in privileges"
                :key="privilege.id"
                label
                :value="privilege.id">
                <span>{{ privilege.name }}</span>
              </v-chip>
            </v-chip-group>
          </v-card>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="$router.push({ name: $routes.user.indexRoute.name })">
              {{ $t("actions.back_to_list") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              @click="createOrUpdateUser">
              {{
                $t("crudActions." + (id
                  ? "update"
                  : "create"))
              }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createUser, updateUser, fetchUser, getParams } from "@/api/user.js";
import { resetData } from "../../utils/ComponentHelper";
import ContentLoader from "../../mixins/ContentLoader.js";
import UserForm from "../../components/user/UserForm.vue";
import Validatable from "../../mixins/Validatable.js";
import _ from "lodash";
import { ValidationObserver } from "vee-validate";

function data() {
  return {
    user: {
      username: null,
      password: null,
      passwordConfirmation: null,
      zoneIds: [],
      privileges: [],
    },
    assignablePrivileges: {},
    zones: [],
  };
}

export default {
	name: "UserCreateOrUpdate",
	components: {
		UserForm,
		ValidationObserver,
	},
	mixins: [ContentLoader, Validatable],
	props: {
		id: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data,
	computed: {
		validationRules() {
			if (typeof this.id === 'number') {
				return {
					username: "required",
					password: "",
					passwordConfirmation: "required_if:password|confirm:password",
          zones: "required",
				};
			}
			return {
				username: "required",
				password: "required",
				passwordConfirmation: "required|confirm:password",
        zones: "required",
			};
		},
	},
  watch: {
    '$route' () {
      this.loadFormData();
    },
    '$store.state.lang' () {
      this.fetchParams();
    }
  },
	created() {
    this.loadFormData();
  },
  methods: {
    async createOrUpdateUser() {
      let userPromise;

      const { validationObserver } = this.$refs,
        isValid = await validationObserver.validate();

      if (isValid) {
        if (this.id) {
          userPromise = updateUser(this.id, this.user);
        } else {
          userPromise = createUser(this.user);
        }
        userPromise.then(() => {
          this.$router.push({ name: this.$routes.user.indexRoute.name });
        }).
          catch(error => this.handleErrorResponse(validationObserver, error));
      }
    },
    async loadFormData() {
      resetData(this.$data, data);
      this.$startLoading();

      if (typeof this.id === 'number') {
        try {
          const response = await fetchUser(this.id);

          this.user = response.data.user;
          await this.$nextTick();
        } catch (e) {
          console.error(e);
        }
      }

      this.fetchParams();

      this.$finishedLoading();
    },
    fetchParams() {
      return getParams().
        then(response => {
          const { privileges, zones } = response.data;
          this.zones = zones;
          this.assignablePrivileges = _.groupBy(privileges, p => p.category);
          if(zones.length === 1) {
            this.user.zoneIds = zones.map(z => z.value);
          }
        });
    },
  },
};
</script>
