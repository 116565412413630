import httpClient from "./httpClient";
import UserUpdateCategory from "@/imported/UserUpdateCategory.json";
import UserIndexCategory from "@/imported/UserIndexCategory.json";

const resource = "users";

export function createUser(user) {
  return httpClient.post(`/${resource}`, { user });
}

export function fetchUsers(global) {
  return httpClient.get(`/${resource}`, { params: { category: global ? UserIndexCategory.global : UserIndexCategory.regular } });
}

export function fetchUser(id) {
  return httpClient.get(`/${resource}/${id}`);
}

export function updateUser(id, user) {
  return httpClient.put(`/${resource}/${id}`, { user, category: UserUpdateCategory.general });
}

export function changeLanguage(id, language) {
  const params = { user: { language }, category: UserUpdateCategory.updateLanguage };
  return httpClient.put(`/${resource}/${id}`, params);
}

export function getParams(userId) {
  return httpClient.get(`/${resource}/params`, { params: { userId } });
}
